.link-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  min-width: 1em;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: black;
  margin: 10px;
}

.link-button:hover {
  background-color: lightgray;
}

.icon {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5em;
}

.icon img {
  width: 70%;
}
